import { default as index7eyjAiD1ykMeta } from "/home/library/www/frontend/pages/[...slug]/index.vue?macro=true";
import { default as index3vJ9jAWQkTMeta } from "/home/library/www/frontend/pages/bible/[bible]/[book]/[chapter]/index.vue?macro=true";
import { default as indexZAAd6XOtplMeta } from "/home/library/www/frontend/pages/bible/[bible]/[book]/index.vue?macro=true";
import { default as indexGaojxHJJvDMeta } from "/home/library/www/frontend/pages/bible/[bible]/index.vue?macro=true";
import { default as bibleIZ2YIFzfRWMeta } from "/home/library/www/frontend/pages/bible/custom/bible.vue?macro=true";
import { default as bookaEfP0GN3hxMeta } from "/home/library/www/frontend/pages/bible/custom/book.vue?macro=true";
import { default as chapterbex4V9CU5OMeta } from "/home/library/www/frontend/pages/bible/custom/chapter.vue?macro=true";
import { default as indexWpuywOY6ITMeta } from "/home/library/www/frontend/pages/bible/custom/index.vue?macro=true";
import { default as indexKBR5vqcZcsMeta } from "/home/library/www/frontend/pages/bible/index.vue?macro=true";
import { default as indexQUQIZqBuFvMeta } from "/home/library/www/frontend/sites/creced/pages/biblioteca/index.vue?macro=true";
import { default as search2EEdifdZ98Meta } from "/home/library/www/frontend/pages/books/search.vue?macro=true";
import { default as _91id_931o7fIz5YRPMeta } from "/home/library/www/frontend/pages/form/[id].vue?macro=true";
import { default as _91id_936tLJaSH9dmMeta } from "/home/library/www/frontend/pages/form/confirmation/[id].vue?macro=true";
import { default as index0MyPbkxPA5Meta } from "/home/library/www/frontend/sites/creced/pages/index.vue?macro=true";
import { default as indexnsRU9v7wS5Meta } from "/home/library/www/frontend/pages/search/[[books]]/index.vue?macro=true";
import { default as _91_91letter_93_93YizrTd2GOvMeta } from "/home/library/www/frontend/pages/tags/[[letter]].vue?macro=true";
import { default as index6vRql27e6xMeta } from "/home/library/www/frontend/pages/user/[id]/index.vue?macro=true";
import { default as indexsJaJwZpJwfMeta } from "/home/library/www/frontend/pages/user/login/index.vue?macro=true";
import { default as index11wMIkAn1yMeta } from "/home/library/www/frontend/pages/user/password/index.vue?macro=true";
import { default as _91_91login_93_93Gd6OgLuYLpMeta } from "/home/library/www/frontend/pages/user/reset/[id]/[timestamp]/[hash]/[[login]].vue?macro=true";
import { default as indexMSHwn0OLmZMeta } from "/home/library/www/frontend/pages/yearlist/index.vue?macro=true";
export default [
  {
    name: "drupal-route",
    path: "/:slug(.*)*",
    meta: index7eyjAiD1ykMeta || {},
    component: () => import("/home/library/www/frontend/pages/[...slug]/index.vue")
  },
  {
    name: "library-bible-book-chapter",
    path: "/bible/:bible()/:book()/:chapter()",
    meta: index3vJ9jAWQkTMeta || {},
    component: () => import("/home/library/www/frontend/pages/bible/[bible]/[book]/[chapter]/index.vue")
  },
  {
    name: "library-bible-book",
    path: "/bible/:bible()/:book()",
    meta: indexZAAd6XOtplMeta || {},
    component: () => import("/home/library/www/frontend/pages/bible/[bible]/[book]/index.vue")
  },
  {
    name: "library-bible",
    path: "/bible/:bible()",
    meta: indexGaojxHJJvDMeta || {},
    component: () => import("/home/library/www/frontend/pages/bible/[bible]/index.vue")
  },
  {
    name: "bible-custom-bible",
    path: "/bible/custom/bible",
    meta: bibleIZ2YIFzfRWMeta || {},
    component: () => import("/home/library/www/frontend/pages/bible/custom/bible.vue")
  },
  {
    name: "bible-custom-book",
    path: "/bible/custom/book",
    meta: bookaEfP0GN3hxMeta || {},
    component: () => import("/home/library/www/frontend/pages/bible/custom/book.vue")
  },
  {
    name: "bible-custom-chapter",
    path: "/bible/custom/chapter",
    meta: chapterbex4V9CU5OMeta || {},
    component: () => import("/home/library/www/frontend/pages/bible/custom/chapter.vue")
  },
  {
    name: "bible-custom",
    path: "/bible/custom",
    meta: indexWpuywOY6ITMeta || {},
    component: () => import("/home/library/www/frontend/pages/bible/custom/index.vue")
  },
  {
    name: "library-bible-parent",
    path: "/bible",
    meta: indexKBR5vqcZcsMeta || {},
    component: () => import("/home/library/www/frontend/pages/bible/index.vue")
  },
  {
    name: "biblioteca",
    path: "/biblioteca",
    meta: indexQUQIZqBuFvMeta || {},
    component: () => import("/home/library/www/frontend/sites/creced/pages/biblioteca/index.vue")
  },
  {
    name: "search-books",
    path: "/books/search",
    meta: search2EEdifdZ98Meta || {},
    component: () => import("/home/library/www/frontend/pages/books/search.vue")
  },
  {
    name: "webform-route",
    path: "/form/:id()",
    meta: _91id_931o7fIz5YRPMeta || {},
    component: () => import("/home/library/www/frontend/pages/form/[id].vue")
  },
  {
    name: "webform-confirmation",
    path: "/form/confirmation/:id()",
    meta: _91id_936tLJaSH9dmMeta || {},
    component: () => import("/home/library/www/frontend/pages/form/confirmation/[id].vue")
  },
  {
    name: "home",
    path: "/",
    meta: index0MyPbkxPA5Meta || {},
    component: () => import("/home/library/www/frontend/sites/creced/pages/index.vue")
  },
  {
    name: "search",
    path: "/search/:books?",
    meta: indexnsRU9v7wS5Meta || {},
    component: () => import("/home/library/www/frontend/pages/search/[[books]]/index.vue")
  },
  {
    name: "tags",
    path: "/tags/:letter?",
    meta: _91_91letter_93_93YizrTd2GOvMeta || {},
    component: () => import("/home/library/www/frontend/pages/tags/[[letter]].vue")
  },
  {
    name: "user-overview",
    path: "/user/:id()",
    meta: index6vRql27e6xMeta || {},
    component: () => import("/home/library/www/frontend/pages/user/[id]/index.vue")
  },
  {
    name: "login",
    path: "/user/login",
    meta: indexsJaJwZpJwfMeta || {},
    component: () => import("/home/library/www/frontend/pages/user/login/index.vue")
  },
  {
    name: "user-password",
    path: "/user/password",
    meta: index11wMIkAn1yMeta || {},
    component: () => import("/home/library/www/frontend/pages/user/password/index.vue")
  },
  {
    name: "passwordResetLogin",
    path: "/user/reset/:id()/:timestamp()/:hash()/:login?",
    meta: _91_91login_93_93Gd6OgLuYLpMeta || {},
    component: () => import("/home/library/www/frontend/pages/user/reset/[id]/[timestamp]/[hash]/[[login]].vue")
  },
  {
    name: "yearlist",
    path: "/yearlist",
    meta: indexMSHwn0OLmZMeta || {},
    component: () => import("/home/library/www/frontend/pages/yearlist/index.vue")
  }
]